import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router';
import { API_URL } from '../../../config/constants';
import axios from 'axios';
import arrowIconImg from '../../../images/filledArrowIcon.png';
import CancelModal from './CancelModal';
import Select from 'react-select';

function CreateData(props) {
    const { modelTypeArr } = useOutletContext();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        count: 0,
        decision: 'OK',
        modelId: 0,
        model: {
            modelType: '',
            modelName: '',
            modelNo: '',
        },
        materialX: 0.0,
        materialXGap: 0.0,
        materialXStatus: 'OK',
        materialY: 0.0,
        materialYGap: 0.0,
        materialYStatus: 'OK',
        peak: 0.0,
        peakGap: 0.0,
        peakStatus: 'OK',
        abnormalPattern: false,
        abnormalPatternStatus: 'OK',
        sizeLeft: 0.0,
        sizeLeftGap: 0.0,
        sizeLeftStatus: 'OK',
        sizeRight: 0.0,
        sizeRightGap: 0.0,
        sizeRightStatus: 'OK',
        ngFactor: '',
        comment: '',
    });
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modelTypes, setModelTypes] = useState([]);
    const [modelNames, setModelNames] = useState([]);
    const [modelNos, setModelNos] = useState([]);



    function onSubmit(e) {
        e.preventDefault();
        console.log(formData)
        if (formData.model.modelName.trim().length === 0 || formData.model.modelType.trim().length === 0 || formData.model.modelNo.trim().length === 0) {
            window.alert('차종/품명/품번이 입력되지 않았습니다.');
        } else {
            axios.post(`${API_URL}/monitoring-manage/createSumData`, {
                newValue: {
                    ...formData,
                    materialXGap: formData.materialXGap / 100,
                    materialYGap: formData.materialYGap / 100,
                    peakGap: formData.peakGap / 100,
                    sizeLeftGap: formData.sizeLeftGap / 100,
                    sizeRightGap: formData.sizeRightGap / 100,
                }
            }).then(res => {
                if (res.data.success) {
                    window.location.href = '/home/data/sumData';
                } else {

                };
            });
        };
    };

    async function onChange(e) {
        e.preventDefault();
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    function onOpenCancelModal(e) {
        e.preventDefault();
        setModalIsOpen(true);
    };

    function onCloseCancelModal(e) {
        e.preventDefault();
        setModalIsOpen(false);
    };

    function onCancel(e) {
        e.preventDefault();
        navigate('/home/data/sumData');
    };

    useEffect(() => {
        setModelTypes([...modelTypeArr.map(item => ({ label: item, value: item }))]);
    }, [modelTypeArr]);

    const decisionOpt = [
        { label: 'NG', value: 'NG' },
        { label: 'OK', value: 'OK' },
    ];

    const abnormalPatternOpt = [
        { label: '없음', value: false },
        { label: '있음', value: true },
    ];
    const ngFactorOpt = [
        { label: '없음', value: '' },
        { label: '원자재 - X', value: '원자재 - X' },
        { label: '원자재 - Y', value: '원자재 - Y' },
        { label: '부하 - Peak', value: '부하 - Peak' },
        { label: '부하 - 이상패턴', value: '부하 - 이상패턴' },
        { label: '치수 - 좌', value: '치수 - 좌' },
        { label: '치수 - 우', value: '치수 - 우' },
    ]

    return (
        <React.Fragment>
            <div className='dataCreateArea'>
                <p className='path'>데이터관리<span className='below'>{'>'}</span>요약데이터<span className='below'>{'>'}</span>데이터추가</p>
                <h2 className='pageTitle'>데이터 추가</h2>
                <div className='pageContent'>
                    <form onSubmit={onSubmit} onChange={onChange}>
                        <table>
                            <tbody>
                                <tr>
                                    <td className='labelTd'><label htmlFor="count">카운트</label></td>
                                    <td className='inputTd' colSpan={5}><input id="count" name="count" type="number" defaultValue={formData.count} /></td>
                                </tr>
                                <tr>
                                    <td className='labelTd'><label htmlFor='decision'>판정</label></td>
                                    <td className='inputTd' colSpan={5}>
                                        <div className='selectArea'>
                                            <Select
                                                options={decisionOpt}
                                                defaultValue={{ label: 'OK', value: 'OK' }}
                                                onChange={sel => setFormData({ ...formData, decision: sel.value })}
                                                isSearchable={false}
                                                classNames={{
                                                    control: (state) => 'control',
                                                    container: (state) => 'selectBox',
                                                    dropdownIndicator: (state) => 'arrow',
                                                    menu: (state) => 'options',
                                                    option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                                    singleValue: (state) => 'singleVal',
                                                    valueContainer: (state) => 'valueContainer',
                                                }}
                                                components={{
                                                    DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={arrowIconImg} alt={'드롭다운'} /></span>,
                                                    IndicatorSeparator: (state) => null,
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='labelTd'><label htmlFor='modelType'>차종</label></td>
                                    <td className='inputTd' colSpan={5}>
                                        <div className='selectArea'>
                                            <Select
                                                options={modelTypes}
                                                defaultValue={{ label: '선택' }}
                                                onChange={async sel => {
                                                    await axios.post(`${API_URL}/android/getModelNames`, { modelType: sel.value }).then(res => {
                                                        setModelNames([...res.data.map(item => ({ label: item, value: item }))]);
                                                        // modelNamesDropdown.current.value = "";
                                                        setModelNos([]);
                                                        // modelNosDropdown.current.value = "";
                                                        setFormData({
                                                            ...formData,
                                                            model: {
                                                                modelType: sel.value,
                                                                modelName: '',
                                                                modelNo: '',
                                                                modelId: 0,
                                                            }
                                                        });
                                                    }).catch(err => console.error(err));
                                                }}
                                                isSearchable={false}
                                                classNames={{
                                                    control: (state) => 'control',
                                                    container: (state) => 'selectBox',
                                                    dropdownIndicator: (state) => 'arrow',
                                                    menu: (state) => 'options',
                                                    option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                                    singleValue: (state) => 'singleVal',
                                                    valueContainer: (state) => 'valueContainer',
                                                }}
                                                components={{
                                                    DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={arrowIconImg} alt={'드롭다운'} /></span>,
                                                    IndicatorSeparator: (state) => null,
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='labelTd'><label htmlFor='modelName'>품명</label></td>
                                    <td className='inputTd' colSpan={5}>
                                        <div className='selectArea'>
                                            <Select
                                                options={modelNames}
                                                value={formData.model.modelName === "" ? { label: '선택' } : modelNames.find(el => el.value === formData.model.modelName)}
                                                onChange={async sel => {
                                                    await axios.post(`${API_URL}/android/getModelNos`, { modelType: formData.model.modelType, modelName: sel.value }).then(res => {
                                                        setModelNos([...res.data.map(item => ({ label: item.modelNo, value: item }))]);
                                                        // modelNosDropdown.current.value = "";
                                                        setFormData({
                                                            ...formData,
                                                            model: {
                                                                ...formData.model,
                                                                modelName: sel.value,
                                                                modelNo: '',
                                                                modelId: 0,
                                                            }
                                                        });
                                                    }).catch(err => console.error(err));
                                                }}
                                                isSearchable={false}
                                                isDisabled={modelNames.length === 0}
                                                classNames={{
                                                    control: (state) => 'control',
                                                    container: (state) => 'selectBox',
                                                    dropdownIndicator: (state) => 'arrow',
                                                    menu: (state) => 'options',
                                                    option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                                    singleValue: (state) => 'singleVal',
                                                    valueContainer: (state) => 'valueContainer',
                                                }}
                                                components={{
                                                    DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={arrowIconImg} alt={'드롭다운'} /></span>,
                                                    IndicatorSeparator: (state) => null,
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='labelTd'><label htmlFor='modelNo'>품번</label></td>
                                    <td className='inputTd' colSpan={5}>
                                        <div className='selectArea'>
                                            <Select
                                                options={modelNos}
                                                value={formData.model.modelNo === "" ? { label: '선택' } : modelNos.find(el => el.value.modelNo === formData.model.modelNo)}
                                                onChange={sel => {
                                                    setFormData({
                                                        ...formData,
                                                        modelId: sel.value.modelId,
                                                        model: {
                                                            ...formData.model,
                                                            modelNo: sel.value.modelNo,
                                                        }
                                                    })
                                                }}
                                                isSearchable={false}
                                                isDisabled={modelNos.length === 0}
                                                classNames={{
                                                    control: (state) => 'control',
                                                    container: (state) => 'selectBox',
                                                    dropdownIndicator: (state) => 'arrow',
                                                    menu: (state) => 'options',
                                                    option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                                    singleValue: (state) => 'singleVal',
                                                    valueContainer: (state) => 'valueContainer',
                                                }}
                                                components={{
                                                    DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={arrowIconImg} alt={'드롭다운'} /></span>,
                                                    IndicatorSeparator: (state) => null,
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='labelTd'><label htmlFor='materialX'>원자재 - X</label></td>
                                    <td className='inputTd firstInput'><input id="materialX" name="materialX" type='number' defaultValue={formData.materialX} /></td>
                                    <td className='labelTd secondLabel'><label htmlFor='materialXGap'>편차</label></td>
                                    <td className='inputTd secondInput'><input id="materialXGap" name="materialXGap" type='number' defaultValue={Math.round(formData.materialXGap * 1000) / 10} />%</td>
                                    <td className='labelTd thirdLabel'><label htmlFor='materialXStatus'>판정</label></td>
                                    <td className='inputTd thirdInput'>
                                        <div className='selectArea'>
                                            <Select
                                                options={decisionOpt}
                                                defaultValue={{ label: 'OK', value: 'OK' }}
                                                onChange={sel => setFormData({ ...formData, materialXStatus: sel.value })}
                                                isSearchable={false}
                                                classNames={{
                                                    control: (state) => 'control',
                                                    container: (state) => 'selectBox',
                                                    dropdownIndicator: (state) => 'arrow',
                                                    menu: (state) => 'options',
                                                    option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                                    singleValue: (state) => 'singleVal',
                                                    valueContainer: (state) => 'valueContainer',
                                                }}
                                                components={{
                                                    DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={arrowIconImg} alt={'드롭다운'} /></span>,
                                                    IndicatorSeparator: (state) => null,
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='labelTd'><label htmlFor='materialY'>원자재 - Y</label></td>
                                    <td className='inputTd firstInput'><input id="materialY" name='materialY' type='number' defaultValue={formData.materialY} /></td>
                                    <td className='labelTd secondLabel'><label htmlFor='materialYGap'>편차</label></td>
                                    <td className='inputTd secondInput'><input id="materialYGap" name='materialYGap' type='number' defaultValue={Math.round(formData.materialYGap * 1000) / 10} />%</td>
                                    <td className='labelTd thirdLabel'><label htmlFor='materialYStatus'>판정</label></td>
                                    <td className='inputTd thirdInput'>
                                        <div className='selectArea'>
                                            <Select
                                                options={decisionOpt}
                                                defaultValue={{ label: 'OK', value: 'OK' }}
                                                onChange={sel => setFormData({ ...formData, materialYStatus: sel.value })}
                                                isSearchable={false}
                                                classNames={{
                                                    control: (state) => 'control',
                                                    container: (state) => 'selectBox',
                                                    dropdownIndicator: (state) => 'arrow',
                                                    menu: (state) => 'options',
                                                    option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                                    singleValue: (state) => 'singleVal',
                                                    valueContainer: (state) => 'valueContainer',
                                                }}
                                                components={{
                                                    DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={arrowIconImg} alt={'드롭다운'} /></span>,
                                                    IndicatorSeparator: (state) => null,
                                                }}
                                            />
                                        </div>

                                    </td>
                                </tr>
                                <tr>
                                    <td className='labelTd'><label htmlFor='peak'>부하 - Peak</label></td>
                                    <td className='inputTd firstInput'><input id="peak" name='peak' type='number' defaultValue={formData.peak} /></td>
                                    <td className='labelTd secondLabel'><label htmlFor='peakGap'>편차</label></td>
                                    <td className='inputTd secondInput'><input id="peakGap" name='peamGap' type='number' defaultValue={Math.round(formData.peakGap * 1000) / 10} />%</td>
                                    <td className='labelTd thirdLabel'><label htmlFor='peakStatus'>판정</label></td>
                                    <td className='inputTd thirdInput'>
                                        <div className='selectArea'>
                                            <Select
                                                options={decisionOpt}
                                                defaultValue={{ label: 'OK', value: 'OK' }}
                                                onChange={sel => setFormData({ ...formData, peakStatus: sel.value })}
                                                isSearchable={false}
                                                classNames={{
                                                    control: (state) => 'control',
                                                    container: (state) => 'selectBox',
                                                    dropdownIndicator: (state) => 'arrow',
                                                    menu: (state) => 'options',
                                                    option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                                    singleValue: (state) => 'singleVal',
                                                    valueContainer: (state) => 'valueContainer',
                                                }}
                                                components={{
                                                    DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={arrowIconImg} alt={'드롭다운'} /></span>,
                                                    IndicatorSeparator: (state) => null,
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='labelTd'><label htmlFor='abnormalPattern'>하중 - 이상패턴</label></td>
                                    <td className='inputTd firstInput'>
                                        <div className='selectArea'>
                                            <Select
                                                options={abnormalPatternOpt}
                                                defaultValue={{ label: '없음', value: false }}
                                                onChange={sel => setFormData({ ...formData, abnormalPattern: sel.value })}
                                                isSearchable={false}
                                                classNames={{
                                                    control: (state) => 'control',
                                                    container: (state) => 'selectBox',
                                                    dropdownIndicator: (state) => 'arrow',
                                                    menu: (state) => 'options',
                                                    option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                                    singleValue: (state) => 'singleVal',
                                                    valueContainer: (state) => 'valueContainer',
                                                }}
                                                components={{
                                                    DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={arrowIconImg} alt={'드롭다운'} /></span>,
                                                    IndicatorSeparator: (state) => null,
                                                }}
                                            />
                                        </div>
                                    </td>
                                    <td className='labelTd secondLabel'><label htmlFor='abnormalPatternStatus'>판정</label></td>
                                    <td className='inputTd' colSpan={3}>
                                        <div className='selectArea'>
                                            <Select
                                                options={decisionOpt}
                                                defaultValue={{ label: 'OK', value: 'OK' }}
                                                onChange={sel => setFormData({ ...formData, abnormalPatternStatus: sel.value })}
                                                isSearchable={false}
                                                classNames={{
                                                    control: (state) => 'control',
                                                    container: (state) => 'selectBox',
                                                    dropdownIndicator: (state) => 'arrow',
                                                    menu: (state) => 'options',
                                                    option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                                    singleValue: (state) => 'singleVal',
                                                    valueContainer: (state) => 'valueContainer',
                                                }}
                                                components={{
                                                    DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={arrowIconImg} alt={'드롭다운'} /></span>,
                                                    IndicatorSeparator: (state) => null,
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                </tr>
                                <tr>
                                    <td className='labelTd'><label htmlFor='sizeLeft'>치수 - 좌</label></td>
                                    <td className='inputTd firstInput'><input id="sizeLeft" name='sizeLeft' type='number' defaultValue={formData.sizeLeft} /></td>
                                    <td className='labelTd secondLabel'><label htmlFor='sizeLeftGap'>편차</label></td>
                                    <td className='inputTd secondInput'><input id="sizeLeftGap" name='sizeLeftGap' type='number' defaultValue={Math.round(formData.sizeLeftGap * 1000) / 10} />%</td>
                                    <td className='labelTd thirdLabel'><label htmlFor='sizeLeftStatus'>판정</label></td>
                                    <td className='inputTd thirdInput'>
                                        <div className='selectArea'>
                                            <Select
                                                options={decisionOpt}
                                                defaultValue={{ label: 'OK', value: 'OK' }}
                                                onChange={sel => setFormData({ ...formData, sizeLeftStatus: sel.value })}
                                                isSearchable={false}
                                                classNames={{
                                                    control: (state) => 'control',
                                                    container: (state) => 'selectBox',
                                                    dropdownIndicator: (state) => 'arrow',
                                                    menu: (state) => 'options',
                                                    option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                                    singleValue: (state) => 'singleVal',
                                                    valueContainer: (state) => 'valueContainer',
                                                }}
                                                components={{
                                                    DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={arrowIconImg} alt={'드롭다운'} /></span>,
                                                    IndicatorSeparator: (state) => null,
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='labelTd'><label htmlFor='sizeRight'>치수 - 우</label></td>
                                    <td className='inputTd firstInput'><input id="sizeRight" name='sizeRight' type='number' defaultValue={formData.sizeRight} /></td>
                                    <td className='labelTd secondLabel'><label htmlFor='sizeRightGap'>편차</label></td>
                                    <td className='inputTd secondInput'><input id="sizeRightGap" name='sizeRightGap' type='number' defaultValue={Math.round(formData.sizeRightGap * 1000) / 10} />%</td>
                                    <td className='labelTd thirdLabel'><label htmlFor='sizeRightStatus'>판정</label></td>
                                    <td className='inputTd thirdInput'>
                                        <div className='selectArea'>
                                            <Select
                                                options={decisionOpt}
                                                defaultValue={{ label: 'OK', value: 'OK' }}
                                                onChange={sel => setFormData({ ...formData, sizeRightStatus: sel.value })}
                                                isSearchable={false}
                                                classNames={{
                                                    control: (state) => 'control',
                                                    container: (state) => 'selectBox',
                                                    dropdownIndicator: (state) => 'arrow',
                                                    menu: (state) => 'options',
                                                    option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                                    singleValue: (state) => 'singleVal',
                                                    valueContainer: (state) => 'valueContainer',
                                                }}
                                                components={{
                                                    DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={arrowIconImg} alt={'드롭다운'} /></span>,
                                                    IndicatorSeparator: (state) => null,
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='labelTd'><label htmlFor='ngFactor'>NG 요인</label></td>
                                    <td className='inputTd' colSpan={5}>
                                        <div className='selectArea'>
                                            <Select
                                                options={ngFactorOpt}
                                                defaultValue={{ label: '없음', value: '' }}
                                                onChange={sel => setFormData({ ...formData, ngFactor: sel.value })}
                                                isSearchable={false}
                                                maxMenuHeight={'140px'}
                                                classNames={{
                                                    control: (state) => 'control',
                                                    container: (state) => 'selectBox',
                                                    dropdownIndicator: (state) => 'arrow',
                                                    menu: (state) => 'options',
                                                    option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                                    singleValue: (state) => 'singleVal',
                                                    valueContainer: (state) => 'valueContainer',
                                                }}
                                                components={{
                                                    DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={arrowIconImg} alt={'드롭다운'} /></span>,
                                                    IndicatorSeparator: (state) => null,
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='labelTd'><label htmlFor='comment'>코멘트</label></td>
                                    <td className='inputTd' colSpan={5}>
                                        <div className='commentArea'>
                                            <textarea id='comment' name='comment' defaultValue={formData.comment} />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='formBtnArea'>
                            <input className='submitBtn' type='submit' value="확인" />
                            <button className='cancelBtn' onClick={onOpenCancelModal}>취소</button>
                        </div>
                    </form>
                </div>
            </div>
            <CancelModal
                isOpen={modalIsOpen}
                onRequestClose={onCloseCancelModal}
                onSubmit={onCancel}
            />
        </React.Fragment>
    );
}

export default CreateData;