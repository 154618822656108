import axios from 'axios';
import React, { useState } from 'react';
import { API_URL } from '../../../config/constants';
import useAsync from '../../../hooks/useAsync';
import { Outlet, useOutletContext } from 'react-router';
// import './data.scss';
import Loading from '../../components/Loading';
import './SumData.scss';

function SumData(props) {

    const {userIsNormal} = useOutletContext();

    async function getDataList() {
        const response = await axios.get(`${API_URL}/monitoring-manage/summarizedDatas`);
        return response.data;
    }

    const [chkAll, setChkAll] = useState(false);
    const [chkedData, setChkedData] = useState([]);
    const [dataArr, setDataArr] = useState([]);

    const state = useAsync(getDataList);
    const { loading, error, data: result } = state;
    if (loading) return <div className='contentArea' style={{ height: '100%' }}><Loading /></div>
    if (error) {
        console.log(error)
        return <div className='contentArea'>페이지를 나타낼 수 없습니다.</div>
    }
    if (!result) return null;
    return (
        <Outlet context={{
            modelTypeArr: result.modelType,
            chkAll: chkAll,
            setChkAll: setChkAll,
            chkedData: chkedData,
            setChkedData: setChkedData,
            dataArr: dataArr,
            setDataArr: setDataArr,
            startedAt: result.startedAt,
            rowCount: result.rowCount,
            userIsNormal: userIsNormal,
        }} />
    );
}

export default SumData;