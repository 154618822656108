import { createBrowserRouter } from "react-router-dom";
import Homepage from "./content/Homepage";
import Summary from "./content/summary";
import Members from "./content/members";
import Data from "./content/data";
import DataStructure from "./content/dataStructure";
import MyPage from "./content/mypage";
import Root from "./content/Root";
import LoginPage from "./content/authPage/LoginPage";
import axios from "axios";
import { API_URL } from "./config/constants";
import MembersMain from "./content/members/MembersMain";
import MembersEdit from "./content/members/MembersEdit";
import SumData from "./content/data/sumData";
import ShowData from "./content/data/sumData/ShowData";
import EditData from "./content/data/sumData/EditData";
import CreateData from "./content/data/sumData/CreateData";
import RealTimeMonitoring from "./content/data/monitoring";
import Model from "./content/data/model";
import UserRank from "./content/data/userRank";
import ShowDataStructures from "./content/dataStructure/ShowDataStructures";
import EditDataStructure from "./content/dataStructure/EditDataStructure";
import CreateDataStructure from "./content/dataStructure/CreateDataStructure";
import ShowMyData from "./content/mypage/ShowMyData";
import EditMyData from "./content/mypage/EditMyData";
import RegisterPage from "./content/authPage/RegisterPage";
import VerifyEmail from "./content/authPage/VerifyEmail";
import ResendAuthMail from "./content/authPage/ResendAuthMail";
import AuthMailInfo from "./content/authPage/AuthMailInfo";
import NotVerifiedYet from "./content/authPage/NotVerifiedYet";
import NaverLoginReq from "./content/authPage/NaverLoginReq";
import SocialRegisterPage from "./content/authPage/SocialRegisterPage";
import ChangePw from "./content/authPage/ChangePw";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        children: [
            {
                path: "home",
                element: <Homepage />,
                children: [
                    {
                        index: true,
                        path: "",
                        element: <Summary />,
                    },
                    {
                        path: "members",
                        element: <Members />,
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <MembersMain />,
                            },
                            {
                                path: "edit/:id",
                                element: <MembersEdit />,
                            },
                        ]
                    },
                    {
                        path: "data",
                        element: <Data />,
                        children: [
                            {
                                path: "sumData",
                                element: <SumData />,
                                children: [
                                    {
                                        index: true,
                                        path: "",
                                        element: <ShowData />,
                                    },
                                    {
                                        path: "edit/:id",
                                        element: <EditData />,
                                    },
                                    {
                                        path: "create",
                                        element: <CreateData />,
                                    },
                                ]
                            },
                            {
                                path: "monitoring",
                                element: <RealTimeMonitoring />,
                            },
                            {
                                path: "model",
                                element: <Model />,
                            },
                            {
                                path: "userRank",
                                element: <UserRank />,
                            },
                        ]
                    },
                    {
                        path: "dataStructure",
                        element: <DataStructure />,
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <ShowDataStructures />,
                            },
                            {
                                path: "edit/:id",
                                element: <EditDataStructure />,
                            },
                            {
                                path: "create",
                                element: <CreateDataStructure />,
                            },
                        ]
                    },
                    {
                        path: "mypage",
                        element: <MyPage />,
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <ShowMyData />,
                            },
                            {
                                path: "edit",
                                element: <EditMyData />,
                            },
                        ]
                    }
                ]
            },
            {
                index: true,
                path: "",
                element: <LoginPage />,
            },
            {
                path: "register",
                element: <RegisterPage />,
            },
            {
                path: "socialRegister",
                element: <SocialRegisterPage />,
            },
            {
                path: "notVerifiedYet/:id",
                element: <NotVerifiedYet />,
            },
            {
                path: "naverLoginReq",
                element: <NaverLoginReq />,
            },
            {
                path: "verifyEmail",
                element: <VerifyEmail />,
            },
            {
                path: "resendAuthMail",
                element: <ResendAuthMail />,
            },
            {
                path: "changePw",
                element: <ChangePw />,
            }
        ]
    }
])