import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import { API_URL } from '../../config/constants';
import arrowIconImg from '../../images/filledArrowIcon.png';
import Select from 'react-select';

function MembersEdit(props) {
    const param = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        userName: '',
        userEmail: '',
        userCompany: '',
        userRank: 9,
    });
    const [userRanks, setUserRanks] = useState([]);

    useEffect(() => {
        axios.post(`${API_URL}/monitoring-manage/getUser`, { id: param.id }).then(res => {
            setFormData({
                userName: res.data.user.userName,
                userEmail: res.data.user.userEmail,
                userCompany: res.data.user.userCompany,
                userRank: res.data.user.userRank,
            });
            setUserRanks([...res.data.userRanks]);
        }).catch(err => console.error(err));
    }, [param.id]);

    function onSubmit(e) {
        e.preventDefault();
        console.log('확인')
        axios.post(`${API_URL}/monitoring-manage/updateUser`, [{ id: param.id, newValue: formData }]).then(res => {
            window.location.href = '/home/members'
        });
    };

    function onChange(e) {
        e.preventDefault();
        setFormData({ ...formData, [e.target.name]: e.target.name === "userRank" ? parseInt(e.target.value) : e.target.value });
    };

    function onChangeSel(sel) {
        setFormData({
            ...formData, userRank: sel.value
        });
    };

    function onCancel(e) {
        e.preventDefault();
        navigate('/home/members');
    };

    const optionArr = userRanks.map(item => ({ label: item.rank, value: item.rank }));

    return (
        <div className='memberEditArea'>
            <p className='path'>회원관리<span className='below'>{'>'}</span>회원수정</p>
            <h2 className='pageTitle'>회원 수정</h2>
            <div className='pageContent'>
                <form onSubmit={onSubmit} onChange={onChange}>
                    <table>
                        <tbody>
                            <tr>
                                <td className='labelTd'><label htmlFor="name">이　름</label></td>
                                <td className='inputTd'><input id='name' name='userName' type='text' defaultValue={formData.userName}></input></td>
                            </tr>
                            <tr style={{ height: '25px' }} />
                            <tr>
                                <td className='labelTd'><label htmlFor="email">이메일</label></td>
                                <td className='inputTd'><input id='email' name='userEmail' type='text' defaultValue={formData.userEmail}></input></td>
                            </tr>
                            <tr style={{ height: '25px' }} />
                            <tr>
                                <td className='labelTd'><label htmlFor="company">소　속</label></td>
                                <td className='inputTd'><input id='company' name='userCompany' type='text' defaultValue={formData.userCompany}></input></td>
                            </tr>
                            <tr style={{ height: '25px' }} />
                            <tr>
                                <td className='labelTd'><label htmlFor="rank">레　벨</label></td>
                                <td className='inputTd'>
                                    <Select
                                        styles={{
                                            menuList: (base) => ({
                                                ...base,
                                                "::-webkit-scrollbar": {
                                                    width: "5px"
                                                },

                                                /* Handle */
                                                "::-webkit-scrollbar-thumb": {
                                                    background: "#0A2B83",
                                                    width: "25px",
                                                    borderRadius: "3px",
                                                    border: "10px solid transparent",
                                                },

                                                /* Track */
                                                "::-webkit-scrollbar-track": {
                                                    marginTop: "14px",
                                                    marginBottom: "14px",
                                                }
                                            })
                                        }}
                                        options={optionArr}
                                        onChange={onChangeSel}
                                        value={optionArr.find(el => el.value === formData.userRank)}
                                        isSearchable={false}
                                        maxMenuHeight={'225px'}
                                        classNames={{
                                            control: (state) => 'control',
                                            container: (state) => 'selectBox',
                                            dropdownIndicator: (state) => 'arrow',
                                            menu: (state) => 'options',
                                            option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                            singleValue: (state) => 'singleVal',
                                                                valueContainer: (state) => 'valueContainer',
                                        }}
                                        components={{
                                            DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={arrowIconImg} alt={'드롭다운'} /></span>,
                                            IndicatorSeparator: (state) => null,
                                        }}
                                    />
                                </td>
                            </tr>

                        </tbody>
                    </table>
                    <div className='formBtnArea'>
                        <input className='formBtn submit' type='submit' value="확인" />
                        <button className='formBtn cancel' onClick={onCancel}>취소</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default MembersEdit;