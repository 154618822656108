import React from 'react';
import { Scrollbars } from 'rc-scrollbars';

function DataListMenu({ monitData, selDataIdx, setSelDataIdx, getMoreData, dbHasMoreData }) {
    return (
        <div className='dataTitleListArea'>
            {
                monitData.length > 0 &&
                <React.Fragment>
                    <div className='selectDataTitle'>데이터 선택</div>
                    {/* <div className='dataTitleContainer' id='scrollArea'> */}
                    <Scrollbars className='dataTitleContainer' id='scrollArea' style={{ width: '255px', height: 'calc(100% - 38px)' }}>
                        <ul className='dataTitleUl'>
                            {
                                monitData.map((el, idx, arr) => {
                                    const isSelected = selDataIdx === idx;
                                    const date = el.date;
                                    return (
                                        <li className='dataTitleLi' key={idx}>
                                            <button
                                                className={isSelected ? 'dataTitleBtn selected' : 'dataTitleBtn'}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    setSelDataIdx(idx);
                                                }}
                                            >
                                                {
                                                    `${date.substr(0, 4)}년 ${parseInt(date.substr(4, 2))}월 ${parseInt(date.substr(6, 2))}일 ${date.substr(9, 2)}:${date.substr(11, 2)}:${date.substr(13, 2)}`
                                                }
                                            </button>
                                        </li>
                                    )
                                })
                            }
                            <li className='dataTitleLi moreData'><button className='dataTitleBtn' onClick={getMoreData} disabled={!dbHasMoreData}>+</button></li>
                            {/* {
                                    loading &&
                                    <li className='dataTitleLi loading'><p>로딩중</p></li>
                                } */}
                        </ul>
                    </Scrollbars>
                    {/* </div> */}
                </React.Fragment>
            }
        </div>
    );
}

export default DataListMenu;