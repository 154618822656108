import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useBlocker, useNavigate } from 'react-router-dom';
import { API_URL } from '../../../config/constants';
import createIconImg from '../../../images/createIcon.png';
import cretaeIconImgOn from '../../../images/createIcon_on.png';
import Select from 'react-select';
import arrowIconImg from '../../../images/filledArrowIcon.png';
import YouWereEditing from '../../components/YouWereEditing';

function ShowUserRank({ userRank }) {
    const [rankList, setRankList] = useState([]);
    const [changed, setChanged] = useState(false);
    const [newRow, setNewRow] = useState(false);
    const [newValue, setNewValue] = useState(null);
    const [restNos, setRestNos] = useState([]);
    const navigate = useNavigate();
    const userRankWithOrigin = userRank.map(el => ({ "original": el.rank, "data": el }));
    const onCreateNewRow = e => {
        if (!newValue) {
            const currentRanks = rankList.map(el => el.data.rank);
            let nonexist = [];
            for (let i = 1; i <= 10; i++) {
                if (currentRanks.indexOf(i) < 0) {
                    nonexist.push(i);
                }
            }
            setNewValue({ rank: null, name: '' });
            setRestNos([...nonexist]);
            setNewRow(true);
            // setChanged(true);
        } else {
            setNewValue(null);
            setNewRow(false);
            setRestNos([]);
            chgMonit();
        }
    }

    // 저장 버튼 이벤트
    const handleCreateObject = async e => {
        e.preventDefault();
        // rank 항목 유일성 체크
        const rankArr = rankList.map(el => el.data.rank);
        if (newValue !== null) rankArr.push(newValue.rank);
        const isDup = rankArr.some(function (x) {
            return rankArr.indexOf(x) !== rankArr.lastIndexOf(x);
        });
        if (isDup) {
            window.alert('RANK는 중복될 수 없습니다.')
            return;
        }
        const dataArr = rankList.map(el => el.data);
        const haveToUpdatedPK = userRank.filter(x => !dataArr.includes(x)).map(el => el.rank);
        const updatedArr = [];
        for (const pk of haveToUpdatedPK) {
            const data = rankList.filter(el => el.original === pk)[0];
            updatedArr.push(data);
        };
        // 수정 사항 이전 rank(PK)체크 후 해당 데이터만 전송
        await axios.post(`${API_URL}/monitoring-manage/updateUserRanks`, { updatedData: updatedArr, newData: newValue }).then(res => {
            if (res.data.success) {
                // onReturnDefault(e);
                navigate(0);
            } else {
                console.error(res.data.error);
            }
        }).catch(err => console.error(err));

    };
    // 초기화 버튼 이벤트
    const onReset = e => {
        e.preventDefault();
        setRankList([...userRankWithOrigin]);
        if (newRow) {
            setNewRow(false);
            setNewValue(null);
        }
        setChanged(false);
    };

    const chgMonit = () => {
        const changed = newValue !== null && newValue.rank !== null && newValue.name.length > 0;
        console.log((newValue !== null && newValue.rank !== null && newValue.name.length > 0));
        setChanged(changed);
    };

    useEffect(() => {
        setRankList([...userRankWithOrigin]);
    }, [userRank]);

    useEffect(() => {
        chgMonit();
    }, [newValue]);

    const [blockPopup, setBlockPopup] = useState(false);
    let blocker = useBlocker(({ currentLocation, nextLocation }) =>
        changed &&
        currentLocation.pathname !== nextLocation.pathname);

    useEffect(() => {
        if (blocker.state === "blocked") {
            setBlockPopup(true);
        } else {
            setBlockPopup(false);
        }
    }, [blocker.state])
    return (
        <React.Fragment>
            <div className='showUserRankArea'>
                <p className='path'>데이터관리<span className='below'>{'>'}</span>유저랭크</p>
                <h2 className='pageTitle'>유저 랭크</h2>
                <div className='userRankTblArea'>
                    <div className='hintBtn'>
                        ?
                        <div className='hintMsg'><p>셀을 직접 클릭하여 수정이 가능합니다.</p></div>
                    </div>
                    <ul className='userRankTbl'>
                        <li className='row th'>
                            <ul>
                                <li className='col'>랭크</li>
                                <li className='col'>설명</li>
                            </ul>
                        </li>
                        {
                            rankList.map((element, index, array) => {
                                return (
                                    <li key={index} className='row'>
                                        <ul>
                                            <li className='col'>
                                                <div className='inputArea'>
                                                    <input type="number" value={element.data.rank} name='rank' min={1} max={10} onChange={
                                                        (e) => {
                                                            if (e.target.value >= 0) {
                                                                let copyArr = [...rankList];
                                                                copyArr[index] = { ...copyArr[index], data: { ...copyArr[index].data, [e.target.name]: parseInt(e.target.value) } };
                                                                setRankList(copyArr);
                                                                setChanged(true);
                                                            } else {
                                                                e.target.value = 0;
                                                            }
                                                        }
                                                    } />
                                                </div>
                                            </li>
                                            <li className='col'>
                                                <div className='inputArea'>
                                                    <input type="text" value={element.data.name} name='name' onChange={
                                                        (e) => {
                                                            let copyArr = [...rankList];
                                                            copyArr[index] = { ...copyArr[index], data: { ...copyArr[index].data, [e.target.name]: e.target.value } };
                                                            setRankList(copyArr);
                                                            setChanged(true);
                                                        }
                                                    } />
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                );
                            })
                        }
                        {
                            newRow ?
                                <li className='row newRow'>
                                    <ul>
                                        <li className='col'>
                                            <div className='inputArea'>
                                                <Select
                                                    options={restNos.map(el => ({ label: el, value: el }))}
                                                    defaultValue={{ label: '+ 추가' }}
                                                    onChange={sel => {
                                                        setNewValue({ ...newValue, rank: sel.value });
                                                    }}
                                                    isSearchable={false}
                                                    classNames={{
                                                        control: (state) => 'control',
                                                        container: (state) => 'selectBox',
                                                        dropdownIndicator: (state) => 'arrow',
                                                        menu: (state) => 'options',
                                                        option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                                        singleValue: (state) => 'singleVal',
                                                        valueContainer: (state) => 'valueContainer',
                                                    }}
                                                    components={{
                                                        DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={arrowIconImg} alt={'드롭다운'} /></span>,
                                                        IndicatorSeparator: (state) => null,
                                                    }}
                                                />
                                            </div>
                                        </li>
                                        <li className='col'>
                                            <div className='inputArea'>
                                                <input type="text" name='name' onChange={
                                                    (e) => {
                                                        // let copyArr = [...rankList];
                                                        // copyArr[rankList.length - 1] = { ...copyArr[rankList.length - 1], data: { ...copyArr[rankList.length - 1].data, [e.target.name]: e.target.value } };
                                                        // setRankList(copyArr);
                                                        setNewValue({ ...newValue, name: e.target.value })
                                                    }
                                                } />
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                                : null
                        }
                    </ul>
                </div>
                <div className='btnsArea cscBtns'>
                    <button className={newRow ? 'createBtn on' : 'createBtn'} onClick={onCreateNewRow}>추가<img src={newRow ? cretaeIconImgOn : createIconImg} alt={"추가"} /></button>
                    <button className='editBtn' onClick={handleCreateObject} disabled={!changed}>저장</button>
                    <button className='cancelBtn' onClick={onReset} disabled={!changed}>취소</button>
                </div>
            </div>
            <YouWereEditing
                isOpen={blockPopup}
                onRequestClose={e => { e.preventDefault(); blocker.reset(); }}
                onSubmit={e => { e.preventDefault(); blocker.proceed(); }}
            />
        </React.Fragment>
    );
}

export default ShowUserRank;